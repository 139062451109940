.ant-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
}
.ant-list * {
  outline: none;
}
.ant-list-pagination {
  margin-top: 24px;
  text-align: right;
}
.ant-list-pagination .ant-pagination-options {
  text-align: left;
}
.ant-list-more {
  margin-top: 12px;
  text-align: center;
}
.ant-list-more button {
  padding-right: 32px;
  padding-left: 32px;
}
.ant-list-spin {
  min-height: 40px;
  text-align: center;
}
.ant-list-empty-text {
  padding: 16px;
  color: hsl(0, 0%, 70%);
  font-size: 14px;
  text-align: center;
}
.ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  color: rgba(0, 0, 0, 0.85);
}
.ant-list-item-meta {
  display: flex;
  flex: 1 1;
  align-items: flex-start;
  max-width: 100%;
}
.ant-list-item-meta-avatar {
  margin-right: 16px;
}
.ant-list-item-meta-content {
  flex: 1 0;
  width: 0;
  color: rgba(0, 0, 0, 0.85);
}
.ant-list-item-meta-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
}
.ant-list-item-meta-title > a {
  color: rgba(0, 0, 0, 0.85);
  transition: all 0.3s;
}
.ant-list-item-meta-title > a:hover {
  color: hsl(227, 95%, 44%);
}
.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
}
.ant-list-item-action {
  flex: 0 0 auto;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}
.ant-list-item-action > li {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}
.ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-item-action-split {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background-color: #f0f0f0;
}
.ant-list-header {
  background: transparent;
}
.ant-list-footer {
  background: transparent;
}
.ant-list-header,
.ant-list-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-list-empty {
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  text-align: center;
}
.ant-list-split .ant-list-item {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-split .ant-list-item:last-child {
  border-bottom: none;
}
.ant-list-split .ant-list-header {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-split.ant-list-empty .ant-list-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-list-loading .ant-list-spin-nested-loading {
  min-height: 32px;
}
.ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-lg .ant-list-item {
  padding: 16px 24px;
}
.ant-list-sm .ant-list-item {
  padding: 8px 16px;
}
.ant-list-vertical .ant-list-item {
  align-items: initial;
}
.ant-list-vertical .ant-list-item-main {
  display: block;
  flex: 1 1;
}
.ant-list-vertical .ant-list-item-extra {
  margin-left: 40px;
}
.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 16px;
}
.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.ant-list-vertical .ant-list-item-action {
  margin-top: 16px;
  margin-left: auto;
}
.ant-list-vertical .ant-list-item-action > li {
  padding: 0 16px;
}
.ant-list-vertical .ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-grid .ant-col > .ant-list-item {
  display: block;
  max-width: 100%;
  margin-bottom: 16px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.ant-list-item-no-flex {
  display: block;
}
.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: right;
}
.ant-list-bordered {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}
.ant-list-bordered .ant-list-header {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-footer {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-item {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}
.ant-list-bordered.ant-list-sm .ant-list-item {
  padding: 8px 16px;
}
.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-footer {
  padding: 8px 16px;
}
.ant-list-bordered.ant-list-lg .ant-list-item {
  padding: 16px 24px;
}
.ant-list-bordered.ant-list-lg .ant-list-header,
.ant-list-bordered.ant-list-lg .ant-list-footer {
  padding: 16px 24px;
}
@media screen and (max-width: 768px) {
  .ant-list-item-action {
    margin-left: 24px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin-left: 24px;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-item {
    flex-wrap: wrap;
  }
  .ant-list-item-action {
    margin-left: 12px;
  }
  .ant-list-vertical .ant-list-item {
    flex-wrap: wrap-reverse;
  }
  .ant-list-vertical .ant-list-item-main {
    min-width: 220px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
.ant-list-rtl {
  direction: rtl;
  text-align: right;
}
.ant-list-rtl .ReactVirtualized__List .ant-list-item {
  direction: rtl;
}
.ant-list-rtl .ant-list-pagination {
  text-align: left;
}
.ant-list-rtl .ant-list-item-meta-avatar {
  margin-right: 0;
  margin-left: 16px;
}
.ant-list-rtl .ant-list-item-action {
  margin-right: 48px;
  margin-left: 0;
}
.ant-list.ant-list-rtl .ant-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 16px;
}
.ant-list-rtl .ant-list-item-action-split {
  right: auto;
  left: 0;
}
.ant-list-rtl.ant-list-vertical .ant-list-item-extra {
  margin-right: 40px;
  margin-left: 0;
}
.ant-list-rtl.ant-list-vertical .ant-list-item-action {
  margin-right: auto;
}
.ant-list-rtl .ant-list-vertical .ant-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 16px;
}
.ant-list-rtl .ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: left;
}
@media screen and (max-width: 768px) {
  .ant-list-rtl .ant-list-item-action {
    margin-right: 24px;
    margin-left: 0;
  }
  .ant-list-rtl .ant-list-vertical .ant-list-item-extra {
    margin-right: 24px;
    margin-left: 0;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-rtl .ant-list-item-action {
    margin-right: 22px;
    margin-left: 0;
  }
  .ant-list-rtl.ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.ant-pagination {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-pagination ul,
.ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}
.ant-pagination-total-text {
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;
}
.ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: "Roboto", sans-serif;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.85);
  transition: none;
}
.ant-pagination-item a:hover {
  text-decoration: none;
}
.ant-pagination-item:hover {
  border-color: hsl(227, 95%, 44%);
  transition: all 0.3s;
}
.ant-pagination-item:hover a {
  color: hsl(227, 95%, 44%);
}
.ant-pagination-item:focus-visible {
  border-color: hsl(227, 95%, 44%);
  transition: all 0.3s;
}
.ant-pagination-item:focus-visible a {
  color: hsl(227, 95%, 44%);
}
.ant-pagination-item-active {
  font-weight: 400;
  background: #fff;
  border-color: hsl(227, 95%, 44%);
}
.ant-pagination-item-active a {
  color: hsl(227, 95%, 44%);
}
.ant-pagination-item-active:hover {
  border-color: #2c5be8;
}
.ant-pagination-item-active:focus-visible {
  border-color: #2c5be8;
}
.ant-pagination-item-active:hover a {
  color: #2c5be8;
}
.ant-pagination-item-active:focus-visible a {
  color: #2c5be8;
}
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  outline: 0;
}
.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
  position: relative;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: hsl(227, 95%, 44%);
  font-size: 12px;
  letter-spacing: -1px;
  opacity: 0;
  transition: all 0.2s;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: hsl(0, 0%, 70%);
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  transition: all 0.2s;
}
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-jump-prev:focus-visible .ant-pagination-item-link-icon,
.ant-pagination-jump-next:focus-visible .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:focus-visible .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus-visible .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-family: "Roboto", sans-serif;
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-pagination-prev,
.ant-pagination-next {
  font-family: Arial, Helvetica, sans-serif;
  outline: 0;
}
.ant-pagination-prev button,
.ant-pagination-next button {
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-pagination-prev:hover button,
.ant-pagination-next:hover button {
  border-color: #2c5be8;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  outline: none;
  transition: all 0.3s;
}
.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link {
  color: hsl(227, 95%, 44%);
  border-color: hsl(227, 95%, 44%);
}
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: hsl(227, 95%, 44%);
  border-color: hsl(227, 95%, 44%);
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover {
  cursor: not-allowed;
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  color: hsl(0, 0%, 70%);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-disabled:focus-visible {
  cursor: not-allowed;
}
.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  color: hsl(0, 0%, 70%);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-slash {
  margin: 0 10px 0 5px;
}
.ant-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
@media all and (-ms-high-contrast: none) {
  .ant-pagination-options *::-ms-backdrop,
  .ant-pagination-options {
    vertical-align: top;
  }
}
.ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  width: auto;
}
.ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 32px;
  margin-left: 8px;
  line-height: 32px;
  vertical-align: top;
}
.ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  transition: all 0.3s;
  width: 50px;
  height: 32px;
  margin: 0 8px;
}
.ant-pagination-options-quick-jumper input::placeholder {
  color: hsl(0, 0%, 60%);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-pagination-options-quick-jumper input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:hover {
  border-color: #2c5be8;
  border-right-width: 1px;
}
.ant-pagination-options-quick-jumper input:focus,
.ant-pagination-options-quick-jumper input-focused {
  border-color: #2c5be8;
  box-shadow: 0 0 0 2px hsla(227, 95%, 44%, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-pagination-options-quick-jumper input-disabled {
  color: hsl(0, 0%, 70%);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-pagination-options-quick-jumper input[disabled] {
  color: hsl(0, 0%, 70%);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-pagination-options-quick-jumper input-borderless,
.ant-pagination-options-quick-jumper input-borderless:hover,
.ant-pagination-options-quick-jumper input-borderless:focus,
.ant-pagination-options-quick-jumper input-borderless-focused,
.ant-pagination-options-quick-jumper input-borderless-disabled,
.ant-pagination-options-quick-jumper input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-pagination-options-quick-jumper input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-pagination-options-quick-jumper input-sm {
  padding: 0px 7px;
}
.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  height: 24px;
  background-color: transparent;
  border: 0;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: hsl(227, 95%, 44%);
}
.ant-pagination-simple .ant-pagination-simple-pager input:focus {
  border-color: #2c5be8;
  box-shadow: 0 0 0 2px hsla(227, 95%, 44%, 0.2);
}
.ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
  color: hsl(0, 0%, 70%);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-mini .ant-pagination-total-text,
.ant-pagination.ant-pagination-mini .ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.ant-pagination-mini .ant-pagination-prev,
.ant-pagination.ant-pagination-mini .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.ant-pagination-mini .ant-pagination-next .ant-pagination-item-link {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.ant-pagination-mini .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination.ant-pagination-mini .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-jump-prev,
.ant-pagination.ant-pagination-mini .ant-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-options {
  margin-left: 2px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-options-size-changer {
  top: 0px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-options-quick-jumper input {
  padding: 0px 7px;
  width: 44px;
  height: 24px;
}
.ant-pagination.ant-pagination-disabled {
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: hsl(0, 0%, 70%);
  background: transparent;
  border: none;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: #e6e6e6;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: hsl(0, 0%, 70%);
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  color: hsl(0, 0%, 70%);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  background: transparent;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link-icon {
  opacity: 0;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-ellipsis {
  opacity: 1;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
  color: hsl(0, 0%, 70%);
}
@media only screen and (max-width: 992px) {
  .ant-pagination-item-after-jump-prev,
  .ant-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: none;
  }
}
.ant-pagination-rtl .ant-pagination-total-text {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-item,
.ant-pagination-rtl .ant-pagination-prev,
.ant-pagination-rtl .ant-pagination-jump-prev,
.ant-pagination-rtl .ant-pagination-jump-next {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-slash {
  margin: 0 5px 0 10px;
}
.ant-pagination-rtl .ant-pagination-options {
  margin-right: 16px;
  margin-left: 0;
}
.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-size-changer.ant-select {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-quick-jumper {
  margin-left: 0;
}
.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager input {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl.ant-pagination.mini .ant-pagination-options {
  margin-right: 2px;
  margin-left: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

